import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent } from 'antd';
import { format } from 'date-fns';
import JSZip from 'jszip';
import { ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';

import parseFilePath from '../../components/Lib/parseFilePath';
import { Document, DocumentType } from '../../generated-api';
import useOcrPageDefinition from '../../hooks/useOcrPageDefinition';
import useDocumentListStore from '../../store/useDocumentListStore';
import convertCSVColumns from '../../utils/convertCSVColumns';
import createCSVData from '../../utils/createCSVData';
import { createTextBlobWithBOM, downloadBlob, downloadCSV } from '../../utils/downloadData';
import { notifyError } from '../../utils/notification';
import ocrResultPageDefinitions from './defs';
import { CSVConvertResult, CSVData, DocumentEntities, ResultPageDocument } from './defs/types';
import OutputFileNameFormModal from './OutputFileNameFormModal';

interface Props {
  type: DocumentType;
  groupId: string;
}

function createCSVFileName(type: DocumentType, result: Document, suffix = ocrResultPageDefinitions[type].name) {
  const { fileName, topLevelDirName: dirName } = parseFilePath(result.user_file_path);
  const today = format(Date.now(), 'yyyyMMdd');
  const limitFileNameLength = 150;
  const outputFileName = dirName ? `${today}_${dirName}_${suffix}.csv` : `${today}_${fileName}_${suffix}.csv`;
  const overLength = outputFileName.length - limitFileNameLength;
  if (dirName) {
    return overLength > 0 ? `${today}_${dirName.slice(0, -overLength)}_${suffix}.csv` : outputFileName;
  } else {
    return overLength > 0 ? `${today}_${fileName.slice(0, -overLength)}_${suffix}.csv` : outputFileName;
  }
}

function createMergedCSVFileName(type: DocumentType, suffix = ocrResultPageDefinitions[type].name) {
  const today = format(Date.now(), 'yyyyMMdd');
  return `${today}_${suffix}.csv`;
}

function convertData(
  values: ResultPageDocument<DocumentEntities>[],
  type: DocumentEntities['type'],
  converterIndex: number
): CSVConvertResult | CSVConvertResult[] {
  const converterItem = ocrResultPageDefinitions[type].csvDataConverters?.[converterIndex];
  if (converterItem?.converter == null && ocrResultPageDefinitions[type].tabs[converterIndex] == null) {
    throw new Error(`Error occurred in convertData().
Did not defined tabs[${converterIndex}] in ocrResultPageDefinitions[${type}].
Or, pleaseAdd the ${converterIndex} converter to csvConverters.`);
  }
  return (
    converterItem?.converter?.(values) ?? {
      csv: convertCSVColumns(values, ocrResultPageDefinitions[type].tabs[converterIndex].csvColumns ?? {}),
    }
  );
}

export const DownloadCsvButton = ({ groupId, type }: Props): ReactElement => {
  const { ocrResults: results } = useDocumentListStore(groupId);
  const { csvDataConverters } = useOcrPageDefinition(type);
  const [converterIndex, setConverterIndex] = useState(0);
  const [outputFormat, setOutputFormat] = useState<'singleDocument' | 'eachDocument'>('eachDocument');
  const [fileName, setFileName] = useState<string>();
  const [csv, setCsv] = useState<string | string[]>();
  const [modalVisible, setModalVisible] = useState(false);

  const onChangeOutputFormat = (e: RadioChangeEvent) => {
    setOutputFormat(e.target.value as 'singleDocument' | 'eachDocument');
  };

  const canOutputAsSingleFile: boolean =
    results?.[0] && results.length >= 2 && ocrResultPageDefinitions[type].canOutputAsSingleCsvFile ? true : false;

  const openFileNameForm = useCallback(() => {
    if (results?.[0]) {
      const convertedData = convertData(results, type, converterIndex);
      if (!Array.isArray(convertedData)) {
        // 単一ファイルのダウンロード
        const csv = createCSVData(convertedData.csv);
        const defaultFileName = createCSVFileName(type, results[0], convertedData.fileNameSuffix);
        setFileName(defaultFileName);
        setCsv(csv);
        setModalVisible(true);
      } else {
        // 複数ファイルのダウンロード
        if (outputFormat == 'singleDocument') {
          // 複数ファイルのCSVDataをマージして一つのファイルをダウンロード
          const mergedCSV: CSVData = convertedData.reduce((result, r) => result.concat(r.csv), [] as CSVData);
          const resultCsv = createCSVData(mergedCSV);
          const defaultFileName = createMergedCSVFileName(type);
          setFileName(defaultFileName);
          setCsv(resultCsv);
          setModalVisible(true);
          return;
        }
        const csv = convertedData.map((item) => createCSVData(item.csv));
        setFileName(createCSVFileName(type, results[0]));
        setCsv(csv);
        setModalVisible(true);
      }
    }
  }, [results, type, converterIndex, outputFormat]);

  const saveCSV = useCallback(() => {
    if (fileName && csv) {
      if (!Array.isArray(csv)) {
        downloadCSV(fileName, csv);
      } else if (csv.length < 5) {
        for (let i = 0; i < csv.length; i++) {
          const fileNameWithoutExtension =
            fileName.indexOf('.') > 0 ? fileName.split('.').slice(0, -1).join('.').replace('.', '_') : fileName;
          downloadCSV(`${fileNameWithoutExtension}_${i + 1}`, csv[i]);
        }
      } else {
        // 5個以上なら zip 化
        const zip = new JSZip();
        for (let i = 0; i < csv.length; i++) {
          const fileNameWithoutExtension =
            fileName.indexOf('.') > 0 ? fileName.split('.').slice(0, -1).join('.').replace('.', '_') : fileName;
          const data = createTextBlobWithBOM(csv[i]);
          zip.file(`${fileNameWithoutExtension}_${i + 1}.csv`, data);
        }
        zip.generateAsync({ type: 'blob' }).then((content) => {
          downloadBlob(`${fileName}.zip`, content);
        });
      }
    } else {
      notifyError('ファイル名が入力されていません');
    }
  }, [fileName, csv]);

  return (
    <Wrap>
      {csvDataConverters && csvDataConverters.length >= 2 && (
        <CsvSelectorWrap>
          出力データ
          <Radio.Group value={converterIndex} onChange={(e) => setConverterIndex(e.target.value)}>
            {csvDataConverters.map((item, index) => (
              <Radio key={index} value={index}>
                {item.labelName}
              </Radio>
            ))}
          </Radio.Group>
        </CsvSelectorWrap>
      )}
      <OutputFileNameFormModal
        fileName={fileName}
        modalVisible={modalVisible}
        saveHandler={saveCSV}
        setFileName={setFileName}
        setModalVisible={setModalVisible}
      />
      <Button icon={<FileExcelOutlined />} type="primary" onClick={openFileNameForm}>
        CSV出力
      </Button>
      {results && canOutputAsSingleFile && (
        <Radio.Group value={outputFormat} onChange={onChangeOutputFormat}>
          <Radio value="singleDocument">1つのファイルにまとめて出力</Radio>
          <Radio value="eachDocument">別々のファイルに出力</Radio>
        </Radio.Group>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 20px 0;
`;

const CsvSelectorWrap = styled.div`
  display: flex;
  gap: 25px;
  user-select: none;
`;
