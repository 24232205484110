import { Input, Modal } from 'antd';
import { ReactElement, useCallback } from 'react';

interface Props {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
  fileName?: string;
  setFileName: (fileName: string) => void;
  saveHandler: () => void;
}
export default function OutputFileNameFormModal({
  fileName,
  modalVisible,
  saveHandler,
  setFileName,
  setModalVisible,
}: Props): ReactElement {
  const changeFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  };

  const onOk = useCallback(() => {
    saveHandler();
    setModalVisible(false);
  }, [saveHandler, setModalVisible]);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  return (
    <>
      <Modal okText="出力" title="出力ファイル名" visible={modalVisible} onCancel={handleCancel} onOk={onOk}>
        <Input value={fileName} onChange={changeFileName} />
      </Modal>
    </>
  );
}
