import { FileExcelOutlined, FileOutlined, FilePdfOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import styled from 'styled-components';

import StaticPageLayout from '../../components/Container/StaticPageLayout';

// type HelpDetails = {
//   label: string;
//   urls?: { type: string; title: string; path: string; extension: 'pdf' | 'xlsx' }[];
//   questionAndAnswer: { question: string; answer: string };
// };

const helpDetails = [
  {
    label: '会計ソフトへの取り込みツール',
    description: (
      <>
        <p>会計ソフトへのインポートフォーマットに変換できるExeclをお使いいただけます。</p>
        <p>使い方は下記動画をご参照下さい。</p>
        <p>
          他の会計ソフトにつきましても、インポートフォーマットをいただき、各項目に入力すべき情報を教えていただけましたら、弊社にて作成いたします。
        </p>
      </>
    ),
    urls: [
      {
        type: 'link',
        title: 'Ez-AiOCR→A-SaaS_変換テンプレート_20241121',
        path: '/Ez-AiOCR→A-SaaS_変換テンプレート_20241121.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→CASH RADAR PBシステム_変換テンプレート_20241121',
        path: '/Ez-AiOCR→CASH RADAR PBシステム_変換テンプレート_20241121.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→freee_変換テンプレート_20241021',
        path: '/Ez-AiOCR→freee_変換テンプレート_20241021.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→ICS_変換テンプレート_20241023',
        path: '/Ez-AiOCR→ICS_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→JDL_変換テンプレート_20241021',
        path: '/Ez-AiOCR→JDL_変換テンプレート_20241021.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→JDL出納帳_変換テンプレート_20241023',
        path: '/Ez-AiOCR→JDL出納帳_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→JOIN_変換テンプレート_20241023',
        path: '/Ez-AiOCR→JOIN_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→MF現金出納長_変換テンプレート_20241202',
        path: '/Ez-AiOCR→MF現金出納長_変換テンプレート_20241202.xlsx',
        extension: 'xlsx',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→MF補助元帳_変換テンプレート_20241023',
        path: '/Ez-AiOCR→MF補助元帳_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→PCA会計_変換テンプレート_20241021',
        path: '/Ez-AiOCR→PCA会計_変換テンプレート_20241021.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→TKCFX2_変換テンプレート_20241023',
        path: '/Ez-AiOCR→TKCFX2_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→TKCFX2クラウド_変換テンプレート_20231120',
        path: '/Ez-AiOCR→TKCFX2クラウド_変換テンプレート_20231120.xlsx',
        extension: 'xlsx',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→エッサム_変換テンプレート_20241121',
        path: '/Ez-AiOCR→エッサム_変換テンプレート_20241121.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→エプソン財務R4_変換テンプレート_20241023',
        path: '/Ez-AiOCR→エプソン財務R4_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→キーパー財務_変換テンプレート_20241023',
        path: '/Ez-AiOCR→キーパー財務_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→ジョブカン_変換テンプレート_20241023',
        path: '/Ez-AiOCR→ジョブカン_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→フリーウェイ_変換テンプレート_20241023',
        path: '/Ez-AiOCR→フリーウェイ_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→マネーリンク_変換テンプレート_202401023',
        path: '/Ez-AiOCR→マネーリンク_変換テンプレート_202401023.xlsx',
        extension: 'xlsx',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→ミロク_変換テンプレート_20241023',
        path: '/Ez-AiOCR→ミロク_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→会計王_変換テンプレート_20241023',
        path: '/Ez-AiOCR→会計王_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→勘定奉行_変換テンプレート_20241023',
        path: '/Ez-AiOCR→勘定奉行_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→勘定奉行OBC_変換テンプレート_20241023',
        path: '/Ez-AiOCR→勘定奉行OBC_変換テンプレート_20241023.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→発展会計_変換テンプレート_20241121',
        path: '/Ez-AiOCR→発展会計_変換テンプレート_20241121.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'link',
        title: 'Ez-AiOCR→弥生会計_変換テンプレート_20241021',
        path: '/Ez-AiOCR→弥生会計_変換テンプレート_20241021.xlsm',
        extension: 'xlsm',
      },
      {
        type: 'video',
        title: 'AiOCR→弥生会計 変換テンプレート 説明動画',
        path: 'https://www.youtube.com/watch?v=F4RpUKhy3A4',
        videoId: 'F4RpUKhy3A4',
      },
    ],
  },
  {
    label: '製品･操作について',
    // urls: [
    //   {
    //     type: 'link',
    //     title: 'Ez-AiOCR 操作マニュアルPDF',
    //     path: '/dummy.pdf',
    //     extension: 'pdf',
    //   },
    //   {
    //     type: 'video',
    //     title: 'EzRobot ロボットの作り方',
    //     path: 'https://www.youtube.com/watch?v=Nu-53dADRe8',
    //     videoId: 'Nu-53dADRe8',
    //   },
    // ],
    questionAndAnswer: [
      {
        question: '製品版に向けてどのような改善を行っている？',
        answer: `\n＜共通＞\n・精度向上を図っています\n\n＜通帳、クレジットカード明細＞\n\
・日付を「YYYYMMDD」でCSV出力するよう修正しています\n\
・全ての金融機関に対応するものを一度にリリースするのでははなく、対応する金融機関を随時増やしていく方針です`,
      },
      {
        question: '読み取り失敗した場合、どのように報告すれば良い？',
        answer:
          '読み取りに失敗した画像ファイルをいただけると、原因調査・改善を行うことができますので、ご協力いただけますとありがたいです。',
      },
      {
        question: '上手く読み取れないファイルの画像ファイルの特徴は？',
        answer: `\n・文字が極端にかすれている\n・手書きのメモがある\n・画像の傾きが大きい\n\
・通帳をスマホ撮影したもののうち、通帳が折れ曲がった状態で撮影している`,
      },
      {
        question: '当座預金通帳、外貨預金通帳は読み取れますか？',
        answer: '普通預金とフォーマットが異なるため、現在は読み取れないケースが多いです。',
      },
      {
        question: 'インターネット通帳（PDFファイル）は読み取れますか？',
        answer:
          'クレジットカード明細とフォーマットが近いため、クレジットカード明細の機能で読み取れるケースが多いです。',
      },
      {
        question:
          'クレジットカード明細の機能で、「納品書」「交通系電子マネー利用履歴」など別ファイルを読み取れてしまうが、別用途で利用しても問題ない？',
        answer: '問題ございません。',
      },
      { question: 'アップロードできるファイルの拡張子は？', answer: 'pdf、jpeg、pngファイルです。' },
      {
        question: 'アップロードした画像ファイルはどこに保存されている？',
        answer: `AWS上に保存されています。\n一定期間が経過したら、画像ファイルは削除される仕様にする予定です。`,
      },
      {
        question: 'セキュリティーは大丈夫？',
        answer: '第三者によるセキュリティー診断を受けており、プログラム面・運用面で問題ないとの報告を受けています。',
      },
      {
        question: 'Mac PCを使っているが、確認・編集画面にて、編集画面が全体表示がされず、編集ができない。',
        answer: (
          <>
            Mac PCにて、スクロールバーを常に表示するよう設定できます。表示されたスクロールバーを使い、編集してください。
            <div>&lt;スクロールバーを有効にする方法&gt;</div>
            <ol>
              <li>画面左上の Apple メニューをクリックします。</li>
              <li>「システム設定」を選択します。</li>
              <li>「外観」をクリックします。</li>
              <li>「スクロールバーの表示」下で「常に表示」をクリックします。</li>
            </ol>
            <img src="/mac_pc_scrollbar_setting.png" width="100%" />
          </>
        ),
      },
    ],
  },
  {
    label: '契約・お支払いについて',
    questionAndAnswer: [
      { question: '契約期間は？', answer: '3ヶ月契約となります。' },
      { question: 'お支払い方法は？', answer: 'クレジットカード決済、もしくは、口座振替となります。' },
      {
        question: '解約したいときは？',
        answer: (
          <>
            <a href="https://forms.gle/F67QuizHY69ikNiF8">こちらの解約フォーム</a>
            よりご登録をお願いいたします。
          </>
        ),
      },
    ],
  },
];

type FixedContentsProps = {
  urls: {
    type: string;
    title: string;
    path: string;
    extension?: string;
    videoId?: string;
  }[];
};
const FixedContents = ({ urls }: FixedContentsProps) => {
  return (
    <FixedContent>
      {urls.map((item) => {
        switch (item.type) {
          case 'video':
            return (
              <iframe
                height="480"
                src={'https://www.youtube.com/embed/' + item.videoId}
                title={item.title}
                width="720"
              />
            );
          case 'link':
            return (
              <DownloadLink download={item.title} href={item.path}>
                <FileIcon extension={item.extension} />
                {item.title}
              </DownloadLink>
            );
          default:
        }
      })}
    </FixedContent>
  );
};

type FileIconProps = {
  extension: string | undefined;
};
const FileIcon = ({ extension }: FileIconProps) => {
  switch (extension) {
    case 'xlsx':
    case 'xlsm':
      return <FileExcelOutlined />;
    case 'pdf':
      return <FilePdfOutlined />;
    default:
      return <FileOutlined />;
  }
};

type QaProps = {
  qa: { question: string; answer: string | JSX.Element }[];
};
const QA = ({ qa }: QaProps) => {
  return (
    <>
      <QaContent>
        <Collapse
          ghost
          expandIcon={({ isActive }) => {
            return isActive ? <MinusOutlined /> : <PlusOutlined />;
          }}
          expandIconPosition="end">
          {qa.map((item, index) => {
            const question = (
              <>
                <Icon>Q</Icon>
                {item.question}
              </>
            );
            return (
              <Collapse.Panel key={index} header={question}>
                <AnswerPanel>
                  <Icon>A</Icon>
                  {item.answer}
                </AnswerPanel>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </QaContent>
    </>
  );
};

const HelpPage = () => {
  return (
    <StaticPageLayout>
      {helpDetails.map((item, index) => {
        return (
          <>
            <Title>{item.label}</Title>
            {item.description && <DescriptionContent>{item.description}</DescriptionContent>}
            {item.urls && <FixedContents urls={item.urls} />}
            {item.questionAndAnswer && <QA key={index} qa={item.questionAndAnswer} />}
          </>
        );
      })}
    </StaticPageLayout>
  );
};

export default HelpPage;

const DescriptionContent = styled.div`
  width: 800px;
  padding: 0 48px;
`;

const FixedContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 800px;
  padding: 0 48px;
  margin-bottom: 48px;
`;

const DownloadLink = styled.a`
  display: block;
  margin-bottom: 8px;
`;

const QaContent = styled.div`
  width: 800px;
  margin-bottom: 48px;
`;

const Title = styled.h1`
  color: #8da9db;
  font-weight: 600;
  margin-bottom: 24px;
`;

const Icon = styled.i`
  display: inline;
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  font-weight: 900;
  margin-right: 16px;
  color: #8da9db;
`;

const AnswerPanel = styled.div`
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 4px;
  white-space: pre-wrap;
`;
