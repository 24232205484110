import styled from 'styled-components';

import StaticPageLayout from '../../components/Container/StaticPageLayout';

const HayawazaPage = () => {
  return (
    <StaticPageLayout>
      <PageTitle>HAYAWAZA特別プラン</PageTitle>
      <Content>
        <VideoContent>
          <Title>HAYAWAZA紹介セミナー</Title>
          <Video src="https://www.youtube.com/embed/v63M0NEUcUw" />
          <Descriptions>
            <Link href="https://youtu.be/v63M0NEUcUw" rel="noreferrer" target="_blank">
              こちらから
            </Link>
            セミナー動画がご視聴できます。
          </Descriptions>
        </VideoContent>
        <VideoContent>
          <Title>HAYAWAZAで記帳がさらに便利に！</Title>
          <Video src="https://www.youtube.com/embed/oA7nGXV3GlM" />
          <Descriptions>AI-OCRデータの列のズレを修正してみました！</Descriptions>
          <Video src="https://www.youtube.com/embed/EYoc6qwgUmc" />
          <Descriptions>AI-OCRデータの列・金額のズレを自動修正します！</Descriptions>
          <Video src="https://www.youtube.com/embed/9yFB1SBW5dw" />
          <Descriptions>AI-OCRデータを名寄せ修正してみました！</Descriptions>
          <Video src="https://www.youtube.com/embed/Tx3daDV75fs" />
          <Descriptions>AI-OCRデータの残高補正機能がバージョンアップしました！</Descriptions>
        </VideoContent>
        <Title>Ez-AiOCR×HAYAWAZA特別プランのご紹介</Title>
        <Link href="https://kachiel.com/2410_hayawaza/">特別プランはこちらのページ下部から申込できます</Link>
      </Content>
    </StaticPageLayout>
  );
};

export default HayawazaPage;

const PageTitle = styled.h1`
  color: #8da9db;
  font-weight: 600;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-size: 16px;
  color: #8da9db;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Link = styled.a`
  font-weight: bold;
`;

const Content = styled.div`
  width: 800px;
  padding: 0 48px;
  margin-bottom: 48px;
`;
const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Video = styled.iframe`
  height: 480px;
  width: 700px;
`;

const Descriptions = styled.p`
  font-weight: 600;
  padding: 8px;
  margin-bottom: 16px;
`;
